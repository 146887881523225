import React from "react";

function Entry({ name, entry }) {
    return (
        <div className="entry">
            <h2 className="blue">{name}</h2>
            <p>{entry}</p>
            <hr />
        </div>
    );
}

export default Entry;
