import React, { useState, useEffect } from "react";
import axios from "axios";

import Entry from "./Entry";
import Pagination from "./Pagination";

function Entries() {
  const [entries, setEntries] = useState(window.entries);

  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(1);

  const totalPages = Math.ceil(total / 30);

  useEffect(() => {
    const isSmoothScrollSupported =
      "scrollBehavior" in document.documentElement.style;

    const scrollToOptions = {
      top: 0,
      left: 0,
      behavior: "smooth",
    };

    if (isSmoothScrollSupported) {
      // Native smooth scrolling
      window.scroll(scrollToOptions);
    } else {
      // Old way scrolling without effects
      window.scroll(scrollToOptions.left, scrollToOptions.top);
    }
    axios
      .get("entries/" + currentPage + ".json")
      .then(function (response) {
        // console.log(response);
        setEntries(response.data.entries);
        setTotal(response.data.total);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [currentPage]);

  return (
    <>
      {entries &&
        entries.length > 0 &&
        entries.map((entry, i) => (
          <Entry
            key={`${entry.name}-${i}`}
            name={entry.name}
            entry={entry.content}
          />
        ))}
      {entries && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      )}
    </>
  );
}
export default Entries;
