import React from "react";
/* eslint-disable */

const NavLink = ({ currentPage, page, setPage }) => {
  if (currentPage === page) {
    return (
      <li className="page-item active">
        <a
          className="page-link"
          title={`Seite ${page + 1} (aktuell)`}
          aria-current="page"
          onClick={() => setPage(page)}
          onKeyPress={() => setPage(page)}
          tabIndex="0">
          <span className="sr-only">Seite</span> {page}
          <span className="sr-only">(aktuell)</span>
        </a>
      </li>
    );
  }
  return (
    <li className="page-item">
      <a
        className="page-link"
        title={`Seite ${page + 1}`}
        aria-current="page"
        onClick={() => setPage(page)}
        onKeyPress={() => setPage(page)}
        tabIndex="0">
        <span className="sr-only">Seite</span> {page}
      </a>
    </li>
  );
};

export default function Pagination({
  currentPage,
  setCurrentPage,
  totalPages,
}) {
  return (
    <nav aria-label="Paginierung">
      <ul className="pagination justify-content-center">
        <li
          className={`page-item page-previous ${
            currentPage === 1 && "disabled"
          }`}>
          <a
            className="page-link"
            title="vorige Seite"
            onClick={() => setCurrentPage(currentPage - 1)}
            tabIndex="0">
            <span
              className="icon icon-navigation_pfeil_links"
              aria-hidden="true"></span>
            <span className="sr-only">vorige Seite</span>
          </a>
        </li>
        <NavLink currentPage={currentPage} page={1} setPage={setCurrentPage} />
        {currentPage > 3 && <li className="page-item hellip"></li>}
        {currentPage !== 1 && currentPage !== 2 && (
          <NavLink
            currentPage={currentPage}
            page={currentPage - 1}
            setPage={setCurrentPage}
          />
        )}
        {currentPage !== 1 && currentPage !== totalPages && (
          <NavLink
            currentPage={currentPage}
            page={currentPage}
            setPage={setCurrentPage}
          />
        )}
        {currentPage !== totalPages && currentPage !== totalPages - 1 && (
          <NavLink
            currentPage={currentPage}
            page={currentPage + 1}
            setPage={setCurrentPage}
          />
        )}
        {totalPages > 5 && currentPage < totalPages - 2 && (
          <li className="page-item hellip"></li>
        )}
        {totalPages > 1 && (
          <NavLink
            currentPage={currentPage}
            page={totalPages}
            setPage={setCurrentPage}
          />
        )}
        <li
          className={`page-item page-next ${
            currentPage === totalPages && "disabled"
          }`}>
          <a
            className="page-link"
            title="nächste Seite"
            tabIndex="0"
            onClick={() => setCurrentPage(currentPage + 1)}>
            <span
              className="icon icon-navigation_pfeil_rechts"
              aria-hidden="true"></span>
            <span className="sr-only">nächste Seite</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}
